import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';

const days = [];
const years = [];

for (let i = 1; i <= 31; i++) {
    days.push(i);
}

for (let i = 2019; i <= 2020; i++) {
    years.push(i);
}

class RequestQuoteDialog extends React.Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Request a Quote</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To request a quote, please fill out and submit the following form.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            />
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            />
                        <TextField
                            margin="dense"
                            id="phone"
                            label="Phone"
                            type="phone"
                            fullWidth
                            />
                        <TextField
                            margin="dense"
                            id="address"
                            label="Event Address (leave blank for on site)"
                            type="text"
                            fullWidth
                            />
                        <TextField
                            margin="dense"
                            id="service"
                            label="Desired date and time of service"
                            type="text"
                            fullWidth
                            />
                        <TextField
                            margin="dense"
                            id="message"
                            label="Detailed description of services requested"
                            type="textarea"
                            fullWidth
                            />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Request
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default RequestQuoteDialog;