import React, { Component } from 'react';
import { Router } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Appbar from './components/PageHeader';
import PageFooter from './components/PageFooter';
import Routes from './routes/index';
import './App.css';

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
});

const history = createBrowserHistory();

class App extends Component {
    constructor(props) {
        super(props);
        this.classes = props;
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Router history={history}>
                    <div className={this.classes.layout}>
                        <Appbar />
                        <Routes />
                        <PageFooter />
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(App);