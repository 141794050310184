import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Chip, Grid, Fab, Paper, Typography } from '@material-ui/core';

import RequestQuoteDialog from '../../components/RequestQuoteDialog';
import { getColor } from '../../Config';

const styles = theme => ({
    main: {
        backgroundColor: getColor('bg-primary'),
        color: theme.palette.common.black,
        marginBottom: theme.spacing.unit * 4,
    },
    mainContent: {
        padding: `${theme.spacing.unit * 6}px`,
    },
    servicesContainer: {
        padding: `${theme.spacing.unit * 6}px`,
    },
    button: {
        margin: theme.spacing.unit,
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: 20,
        right: 20,
        position: 'fixed',        
    },
    card: {
        backgroundColor: getColor('bg-secondary')
    }
});

const foods = {
    meats: [
        {
            name: 'Jerk Pork Loin',
            description: 'A jerk pork loin with cajun rice and a pineapple cucumber salsa.',
            image: '/images/pork.jpg',
            tags: []
        },{
            name: 'Beef Tenderloin',
            description: 'Beef tenderloin marinated in chimichurri sauce with goat cheese polenta and roasted asparagus.',
            image: '/images/beef.jpg',
            tags: []
        },{
            name: 'Chicken Mole',
            description: 'Pulled chicken mole.',
            image: '/images/mole.jpg',
            tags: []
        },
        {
            name: 'Tamales',
            description: 'Several varieties. Pictured: roasted pulled bbq pork and cheddar (homemade bbq sauce), tomato chipotle chicken (brined), and roasted veggie con queso.',
            image: '/images/tamales2.jpg',
            tags: ['Gluten Free']
        },
    ],
    nonMeats: [
        {
            name: 'Roasted Squash Soup',
            description: 'Roasted squash soup made with a homemade cashew cream, topped with pepitas, feta, a paprika avocado oil, and a pumpkin feta muffin. Can be made vegan and GF.',
            image: '/images/squash.jpg',
            tags: ['Vegan', 'Gluten Free']
        },
    ],
    desserts: [
        {
            name: 'Kiwi Lime Cheesecake Bites',
            description: 'Raw vegan gluten free kiwi lime cheesecake bites.',
            image: '/images/kiwi2.jpg',
            tags: ['Raw Vegan', 'Vegan', 'Gluten Free']
        },{
            name: 'Chocolate Chip Cookie Dough Bites',
            description: '',
            image: '/images/chocolatechip.jpg',
            tags: ['Vegan', 'Gluten Free', 'Nut Free', 'Refined Sugar Free']
        },{
            name: 'Raw Vegan Lemon Blueberry Cheesecake Bites',
            description: '',
            image: '/images/cheesecake.jpg',
            tags: ['Raw Vegan', 'Vegan', 'Gluten Free', 'Refined Sugar Free']
        },{
            name: 'Banana Upside Down Cake',
            description: '',
            image: '/images/cake.jpg',
            tags: ['Not', 'Sure', 'Exactly']
        },{
            name: 'Lemon Cake',
            description: 'Double layer olive oil lemon cake with homemade lemon curd frosting and local blueberries.',
            image: '/images/lemoncake.png',
            tags: ['HAS gluten', 'Dairy', 'Sugar']
        },
    ]
};

function mapCard(classes, food) {
    return (
        <Grid item key={food.name} xs={12} md={3}>
            <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardMedia
                        component="img"
                        alt={food.name}
                        title={food.name}
                        className={classes.media}
                        image={food.image}
                        />
                    <CardContent>
                        <Typography component="h2" variant="h5">{food.name}</Typography>
                        <Typography variant="subtitle1" paragraph>{food.description}</Typography>
                        {food.tags.map(tag => (
                            <Chip key={tag} label={tag} className={classes.chip} />
                        ))}
                    </CardContent>
                </div>
            </Card>
        </Grid>
    );
}

function MealPrep(props) {
    const { classes } = props;
    const dialogRef = useRef();

    return (
        <div>
            <RequestQuoteDialog ref={dialogRef} />
            <Fab color="primary" variant="extended" aria-label="Request a Quote" className={classes.fab} onClick={() => dialogRef.current.handleClickOpen()}>
                Request a Quote
            </Fab>
            <Paper className={classes.main}>
                <Grid container>
                    <Grid item>
                        <div className={classes.mainContent}>
                            <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                                Weekly Meal Prep
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                For our weekly meal prep service, you choose the meals you want to eat throughout the week, and we come to you and prepare them all at once, then you simple heat the food when you are ready to eat it.
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                Below is a sample of our offerings.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.main}>
                <Grid container spacing={40} className={classes.servicesContainer}>
                    <Grid item xs={12}>
                        <div>
                            <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                                Meat Entrees
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                A selection of meat dishes that are available.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container item spacing={40} className={classes.cardGrid}>
                        {foods.meats.map(food => mapCard(classes, food))}
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.main}>
                <Grid container spacing={40} className={classes.servicesContainer}>
                    <Grid item xs={12}>
                        <div>
                            <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                                Non-Meat Entrees
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                A selection of non-meat entrees that are available.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container item spacing={40} className={classes.cardGrid}>
                        {foods.nonMeats.map(food => mapCard(classes, food))}
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.main}>
                <Grid container spacing={40} className={classes.servicesContainer}>
                    <Grid item xs={12}>
                        <div>
                            <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                                Desserts
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                A selection of desserts.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container item spacing={40} className={classes.cardGrid}>
                        {foods.desserts.map(food => mapCard(classes, food))}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

MealPrep.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MealPrep);