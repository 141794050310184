import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';

const styles = theme => ({
    servicesPaper: {
        marginBottom: theme.spacing.unit * 4,
    },
    servicesContainer: {
        padding: `${theme.spacing.unit * 6}px`,
    },
    center: {
        textAlign: 'center'
    }
});

function LoginPage(props) {
    const { classes } = props;
    
    return (
        <div>
            <Paper className={classes.servicesPaper}>
                <div className={classes.servicesContainer}>
                    <Grid container justify="center">
                        <Grid item xs={12} sm={8}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Employee Login
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                margin="dense"
                                id="username"
                                label="Username"
                                type="text"
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                margin="dense"
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Button component={Link} to='/'>Cancel</Button>
                            <Button component={Link} to='/dashboard'>Login</Button>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </div>
    );
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginPage);