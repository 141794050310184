import React from "react";
import { Route } from "react-router-dom";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Main from "../pages/Main";
import AboutPage from "../pages/AboutPage";

import ServicesPage from "../pages/ServicesPage";
import CateringPage from "../pages/services/CateringPage";
import DietitianPage from "../pages/services/DietitianPage";
import MassagePage from "../pages/services/MassagePage";
import MealPrepPage from "../pages/services/MealPrepPage";

import LoginPage from "../pages/dashboard/LoginPage";
import DashboardPage from "../pages/dashboard/DashboardPage";

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: "hidden",
        position: "relative",
        display: "flex"
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        minWidth: 0 // So the Typography noWrap works
    },
});

const Routes = props => {
    const { classes } = props;

    return (
        <div>
            <div className={classes.root}>
                <main className={classes.content}>
                    <Route exact path="/" component={Main} />
                    <Route path="/main" component={Main} />
                    <Route path="/about" component={AboutPage} />

                    <Route path="/allServices" component={ServicesPage} />
                    <Route path="/services/catering" component={CateringPage} />
                    <Route path="/services/dietitian" component={DietitianPage} />
                    <Route path="/services/massage" component={MassagePage} />
                    <Route path="/services/mealprep" component={MealPrepPage} />

                    <Route path="/login" component={LoginPage} />
                    <Route path="/dashboard" component={DashboardPage} />
                </main>
            </div>
        </div>
    );
};

Routes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Routes);