import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Button, Grid, Toolbar } from '@material-ui/core';

import {getColor} from '../Config';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
    },
    toolbarMain: {
        borderBottom: `1px solid ${getColor('bg-primary')}`,
        backgroundColor: getColor('bg-primary'),
        color: theme.palette.common.black,
    },
    toolbarTitle: {
        flex: 1,
    },
    chip: {
        margin: theme.spacing.unit,
    },
});

function ButtonAppBar(props) {
    const { classes } = props;
    
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolbarMain}>
                    <Grid container spacing={16} justify="space-between">
                        <Grid item xs={12} md={6}>
                            <Button color="inherit" size="large" component={Link} to="/">
                                Wabi Sabi Body
                            </Button>
                            <Button color="inherit" component={Link} to="/about">
                                About Us
                            </Button>
                            <Button color="inherit" component={Link} to="/allServices">
                                Services
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);