import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
    },
    toolbarMain: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[800],
    },
    toolbarTitle: {
        flex: 1,
    },
    chip: {
        margin: theme.spacing.unit,
    },
});

function PageFooter(props) {
    const { classes } = props;
    
    return (
        <div className={classes.root}>
            <footer className={classes.footer}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="center" gutterBottom>
                            Wabi Sabi Body &copy; 2019
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button color="inherit" component={Link} to="/">
                            Home
                        </Button>
                        <Button color="inherit" component={Link} to="/about">
                            About Us
                        </Button>
                        <Button color="inherit" component={Link} to="/allServices">
                            Services
                        </Button>
                        <Button color="inherit" component={Link} to="/login">
                            Employee Login
                        </Button>
                    </Grid>
                </Grid>
            </footer>
        </div>
    );
}

PageFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageFooter);