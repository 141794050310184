import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Hidden, Paper, Typography } from '@material-ui/core';

import {getColor} from '../Config';

const styles = theme => ({
    servicesPaper: {
        backgroundColor: getColor('bg-primary'),
        color: theme.palette.common.black,
        marginBottom: theme.spacing.unit * 4,
    },
    servicesContainer: {
        padding: `${theme.spacing.unit * 6}px`,
    },
    card: {
        display: 'flex',
        backgroundColor: getColor('bg-secondary'),
    },
    cardDetails: {
        flex: 1,
    },
    bigMedia: {
        width: 160
    },
    media: {
        objectFit: 'cover',
    },
    center: {
        textAlign: 'center'
    }
});

const services = [
    {
        title: 'Weekly Meal Prep',
        description: 'We come to you and make things that you can snack on all week.',
        image: '/images/tamales.jpg',
        link: '/services/mealprep',
    },{
        title: 'Catered Special Events',
        description: 'We come to you and make food for parties or intimate date nights.',
        image: '/images/squash.jpg',
        link: '/services/catering',
    },{
        title: 'Massage',
        description: 'Either in-home massage or you come to us massage.',
        image: '/images/massage.jpg',
        link: '/services/massage',
    },{
        title: 'Dietitian',
        description: 'Work with our dietitian to create a diet that\'s right for you.',
        image: '/images/dietitian.jpg',
        link: '/services/dietitian',
    },
];

function Services(props) {
    const { classes } = props;
    
    return (
        <Paper className={classes.servicesPaper}>
            <Grid container spacing={40} className={classes.servicesContainer}>
                <Grid item xs={12}>
                    <Typography component="h2" variant="h3" color="inherit" gutterBottom className={classes.center}>
                        Services
                    </Typography>
                </Grid>
                <Grid container item spacing={40} className={classes.cardGrid}>
                    {services.map(service => (
                        <Grid item key={service.title} xs={12} md={6}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to={service.link}>
                                    <div className={classes.cardDetails}>
                                        <Hidden smUp>
                                            <CardMedia
                                                component="img"
                                                alt={service.title}
                                                title={service.title}
                                                className={classes.media}
                                                height="160"
                                                image={service.image}
                                                />
                                        </Hidden>
                                        <CardContent>
                                            <Typography component="h2" variant="h5">{service.title}</Typography>
                                            <Typography variant="subtitle1" paragraph>{service.description}</Typography>
                                            <Typography variant="subtitle1" color="primary">Explore</Typography>
                                        </CardContent>
                                    </div>
                                </CardActionArea>
                                <Hidden xsDown>
                                    <CardMedia
                                        alt={service.title}
                                        title={service.title}
                                        className={classes.bigMedia}
                                        height="160"
                                        image={service.image}
                                        />
                                </Hidden>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
}

Services.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Services);