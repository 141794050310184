import React from 'react';
import PropTypes from 'prop-types';

import Services from '../components/Services';

function ServicesPage(props) {
    return (
        <div>
            <Services />
        </div>
    );
}

ServicesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ServicesPage;