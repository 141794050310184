import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
    servicesPaper: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing.unit * 4,
    },
    servicesContainer: {
        padding: `${theme.spacing.unit * 6}px`,
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    avatar: {
        margin: 10,
        width: 60,
        height: 60
    },
    smallAvatar: {
        margin: 5,
    },
    center: {
        textAlign: 'center'
    }
});

function DashboardPage(props) {
    const { classes } = props;
    
    return (
        <div>
            <Paper className={classes.servicesPaper}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.servicesContainer}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Wabi Sabi Body Dashboard
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper>
                <div className={classes.servicesContainer}>
                    <Grid container spacing={40}>
                        <Grid item xs={12} sm={6}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                New quote requests
                            </Typography>
                            <Grid container spacing={40}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Gregory Propst, gregory@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the food please. Thank you.</Typography>
                                                <Button color="inherit">Mark as Read</Button>
                                                <Button color="inherit">Reply</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Ninja Propst, ninja@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the fish please. Thank you.</Typography>
                                                <Button color="inherit">Mark as Read</Button>
                                                <Button color="inherit">Reply</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Upcoming meal prep
                            </Typography>
                            <Grid container spacing={40}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Gregory Propst, gregory@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the food please. Thank you.</Typography>
                                                <Button color="inherit">Contact</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Ninja Propst, ninja@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the fish please. Thank you.</Typography>
                                                <Button color="inherit">Contact</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Upcoming catering events
                            </Typography>
                            <Grid container spacing={40}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Gregory Propst, gregory@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the food please. Thank you.</Typography>
                                                <Button color="inherit">Contact</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Ninja Propst, ninja@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the fish please. Thank you.</Typography>
                                                <Button color="inherit">Contact</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Upcoming dietitian appointments
                            </Typography>
                            <Grid container spacing={40}>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Gregory Propst, gregory@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the food please. Thank you.</Typography>
                                                <Button color="inherit">Contact</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <div className={classes.cardDetails}>
                                            <CardContent>
                                                <Typography component="h2" variant="h5">Ninja Propst, ninja@prop.st, 515-555-5555</Typography>
                                                <Typography variant="subtitle1" paragraph>Quote received: Mon March 12 @ 11pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Event date: Tue March 13 @ 1pm</Typography>
                                                <Typography variant="subtitle1" paragraph>Message: I would like all of the fish please. Thank you.</Typography>
                                                <Button color="inherit">Contact</Button>
                                            </CardContent>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </div>
    );
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardPage);