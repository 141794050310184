import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
    main: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing.unit * 4,
    },
    mainContent: {
        padding: `${theme.spacing.unit * 6}px`,
        [theme.breakpoints.up('md')]: {
            paddingRight: 0,
        },
    },
    button: {
        margin: theme.spacing.unit,
    },
});

function Dietitian(props) {
    const { classes } = props;
    
    return (
        <div>
            <Paper className={classes.main}>
                <Grid container>
                    <Grid item>
                        <div className={classes.mainContent}>
                            <Typography component="h3" variant="h4" color="inherit" gutterBottom>
                                Dietitian
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                TODO
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

Dietitian.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dietitian);