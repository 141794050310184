import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Card, CardContent, Grid, Hidden, Paper, Typography } from '@material-ui/core';

import { getString, getColor } from '../Config';

const styles = theme => ({
    servicesPaper: {
        backgroundColor: getColor('bg-primary'),
        color: theme.palette.common.black,
        marginBottom: theme.spacing.unit * 4,
    },
    servicesContainer: {
        padding: `${theme.spacing.unit * 6}px`,
    },
    card: {
        display: 'flex',
        backgroundColor: getColor('bg-secondary')
    },
    cardDetails: {
        flex: 1,
    },
    center: {
        textAlign: 'center'
    },
    avatar: {
        margin: 10,
        width: 60,
        height: 60
    },
    smallAvatar: {
        margin: 5,
    }
});

const persons = [
    {
        name: 'RaeAnn',
        text: getString('About.raeann'),
        image: '/images/testimonial1.jpeg',
    },{
        name: 'Totally Notacat',
        text: getString('About.notacat'),
        image: '/images/testimonial2.jpg',
    }
];

function AboutPage(props) {
    const { classes } = props;
    
    return (
        <div>
            <Paper className={classes.servicesPaper}>
                <Grid container>
                    <Grid item>
                        <div className={classes.servicesContainer}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Wabi Sabi Body
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph>
                                {getString('About.description')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.servicesPaper}>
                <Grid container spacing={40} className={classes.servicesContainer}>
                    <Grid item xs={12}>
                        <Typography component="h2" variant="h3" color="inherit" gutterBottom className={classes.center}>
                            Collected People
                        </Typography>
                    </Grid>
                    <Grid container item spacing={40} className={classes.cardGrid} justify="center" alignItems="center">
                        {persons.map(person => (
                            <Grid item key={person.name} sm={8} xs={12}>
                                <Card className={classes.card}>
                                    <div className={classes.cardDetails}>
                                        <CardContent>
                                            <Grid container>
                                                <Grid item xs={2} sm={1}>
                                                    <Hidden xsDown>
                                                        <Avatar alt={person.name} src={person.image} className={classes.avatar} />
                                                    </Hidden>
                                                    <Hidden smUp>
                                                        <Avatar alt={person.name} src={person.image} className={classes.smallAvatar} />
                                                    </Hidden>
                                                </Grid>
                                                <Grid item xs={10} sm={11}>
                                                    <Typography component="h2" variant="h5">{person.name}</Typography>
                                                    {person.text.map(line => (
                                                        <Typography variant="subtitle1" paragraph>{line}</Typography>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

AboutPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutPage);